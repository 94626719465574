import { __assign, __awaiter, __generator, __rest } from "tslib";
/* eslint-disable @typescript-eslint/no-shadow */
import './index.scss';
import * as React from 'react';
import { getPopupContainer } from '@cainiaofe/cn-ui-common';
import { useRequest } from 'ahooks';
import isFunction from 'lodash/isFunction';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { Select as NextSelect } from '@/components/fusion';
import { CnLoading } from '@/components/cn-loading';
import { loopDataSource } from '@/components/cn-utils';
import { packRequest as request } from '@/components/cn-utils/old-request';
import { CnReadOnly } from '@/components/cn-read-only';
var NextAutoComplete = NextSelect.AutoComplete;
function handleRequestService(requestConfig, remoteUrl) {
    var _this = this;
    var _a = requestConfig || {}, service = _a.service, _b = _a.searchKey, searchKey = _b === void 0 ? 'key' : _b, paramsProps = _a.params, dataProps = _a.data, _c = _a.searchFormat, searchFormatProps = _c === void 0 ? function (params) { return params; } : _c, formatParam = _a.formatParam, _d = _a.formatResult, formatResult = _d === void 0 ? function (res) {
        var _a;
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        return [];
    } : _d, _e = _a.method, method = _e === void 0 ? 'GET' : _e;
    if (isFunction(service)) {
        return function (searchConfig) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                return [2 /*return*/, (_a = service(searchConfig)) === null || _a === void 0 ? void 0 : _a.then(function (data) {
                        var dataSource = formatResult(data);
                        return Array.isArray(dataSource) ? dataSource : [];
                    })];
            });
        }); };
    }
    if (remoteUrl) {
        return function (searchConfig) { return __awaiter(_this, void 0, void 0, function () {
            var searchFormat, params, data, resultData, dataSource;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        searchFormat = formatParam !== null && formatParam !== void 0 ? formatParam : searchFormatProps;
                        params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                            ? searchFormat(__assign((_a = {}, _a[searchKey] = searchConfig, _a), paramsProps))
                            : paramsProps;
                        data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                            ? searchFormat(__assign((_b = {}, _b[searchKey] = searchConfig, _b), dataProps))
                            : dataProps;
                        return [4 /*yield*/, request(__assign(__assign({}, requestConfig), { params: params, data: data, url: remoteUrl }))];
                    case 1:
                        resultData = _c.sent();
                        dataSource = formatResult(resultData);
                        return [2 /*return*/, Array.isArray(dataSource) ? dataSource : []];
                }
            });
        }); };
    }
    return undefined;
}
function handleErrorInfo(error) {
    if (!error)
        return {};
    return {
        autoWidth: false,
        dataSource: [],
        notFoundContent: React.createElement("div", { className: "cn-async-select-error" }, "\u8BF7\u6C42\u6570\u636E\u5F02\u5E38"),
    };
}
function handleLoading(loading, data) {
    var ContentLoading = function () {
        return (React.createElement("div", { className: "content-loading" },
            React.createElement(CnLoading, { size: "medium" })));
    };
    var IsContentLoading = (data === null || data === void 0 ? void 0 : data.length) === 0 && loading;
    if (IsContentLoading) {
        return {
            notFoundContent: React.createElement(ContentLoading, null),
        };
    }
    if (loading) {
        return {
            state: 'loading',
        };
    }
    return {
        state: undefined,
    };
}
function onSearchClear(run, onSearchClearProps, actionType) {
    onSearchClearProps(actionType, run);
}
export var AutoComplete = React.forwardRef(function (props, ref) {
    var _a;
    var url = props.remoteUrl, requestConfig = props.requestConfig, initRequestConfig = props.initRequestConfig, readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, onVisibleChangeProps = props.onVisibleChange, onFocusProps = props.onFocus, _b = props.onSearchClear, onSearchClearProps = _b === void 0 ? function () { } : _b, popupContainer = props.popupContainer, $i18n = props.$i18n, valueRender = props.valueRender, fieldNames = props.fieldNames, otherProps = __rest(props, ["remoteUrl", "requestConfig", "initRequestConfig", "readOnly", "readOnlyProps", "onVisibleChange", "onFocus", "onSearchClear", "popupContainer", "$i18n", "valueRender", "fieldNames"]);
    // useRequest 相关配置
    var _c = requestConfig || {}, service = _c.service, searchFormat = _c.searchFormat, ConfighUrl = _c.url, otherConfig = __rest(_c, ["service", "searchFormat", "url"]);
    var remoteUrl = ConfighUrl || url;
    var insertSelectProps = {};
    var requestService = (_a = handleRequestService(requestConfig, remoteUrl)) !== null && _a !== void 0 ? _a : function () {
        return Promise.resolve([]);
    };
    var _d = useRequest(requestService, __assign({ ready: !!(remoteUrl || service) }, otherConfig)), run = _d.run, error = _d.error, _e = _d.data, data = _e === void 0 ? [] : _e, loading = _d.loading;
    var onVisibleChange = function (visible, type) {
        if (isFunction(onVisibleChangeProps)) {
            onVisibleChangeProps(visible, type, run);
        }
    };
    var onFocus = function (params) {
        if (isFunction(onFocusProps)) {
            onFocusProps(params, run);
        }
    };
    // const onVisibleChange = (visible: boolean, type?: string) => {
    //   if (isFunction(onVisibleChangeProps)) {
    //     onVisibleChangeProps(visible, type, run);
    //   }
    // };
    insertSelectProps.dataSource = data;
    var errorInfo = handleErrorInfo(error);
    var loadingInfo = handleLoading(loading, data);
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // onSearch 自动包装
    // 仅在 showSearch 且 filterLocal 为 false 情况下启用
    var enableOnSearch = otherProps.showSearch &&
        !otherProps.onSearch &&
        otherProps.filterLocal === false;
    if (enableOnSearch) {
        insertSelectProps.onSearch = function (inputValue) {
            run(inputValue);
        };
    }
    insertSelectProps.isPreview = readOnly;
    insertSelectProps.renderPreview = function (values) {
        return (React.createElement(CnReadOnly, __assign({ type: "enum", value: values }, __assign(__assign({}, pick(props, [
            'addonBefore',
            'innerBefore',
            'addonTextBefore',
            'addonTextAfter',
            'innerAfter',
            'addonAfter',
        ])), readOnlyProps))));
    };
    var dataSource = 'dataSource' in props ? props.dataSource : data;
    if (fieldNames) {
        dataSource = loopDataSource(dataSource, fieldNames);
    }
    var onSearchClearCallBack = React.useMemo(function () {
        return onSearchClear.bind(null, run, onSearchClearProps);
    }, []);
    return (React.createElement(NextAutoComplete, __assign({ "data-name": "CnAsyncSelect", ref: ref, popupContainer: getPopupContainer(popupContainer) }, insertSelectProps, omit(otherProps, ['$i18n']), { dataSource: dataSource, onVisibleChange: onVisibleChange, onFocus: onFocus, onSearchClear: onSearchClearCallBack })));
});
