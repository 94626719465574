import { __assign } from "tslib";
import * as React from 'react';
import NextDropdown from '@fusion/lib/dropdown';
export var CnDropdown = function (props) {
    return React.createElement(NextDropdown, __assign({ "data-name": "CnDropdown" }, props));
};
CnDropdown.displayName = 'CnDropdown';
/**
 * @deprecated  请使用 CnDropdown 替换
 */
export var Dropdown = NextDropdown;
