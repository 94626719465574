import { useEffect } from 'react';
export function getFormUploadProps(params) {
    var fileListRef = params.fileListRef, field = params.field, props = params.props, uploadErrMsg = params.uploadErrMsg;
    useEffect(function () {
        var validatorList = [];
        if (field.validator) {
            if (Array.isArray(field.validator)) {
                for (var _i = 0, _a = field.validator; _i < _a.length; _i++) {
                    var item = _a[_i];
                    validatorList.push(item);
                }
            }
            validatorList.push(field.validator);
        }
        validatorList.push({
            triggerType: 'onInput',
            message: uploadErrMsg,
            validator: function () {
                fileListRef.current = fileListRef.current.filter(function (item) {
                    var _a;
                    return !((_a = field.value) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
                        var key = _a.key;
                        return item.key === key;
                    }));
                });
                if (fileListRef.current.length) {
                    return uploadErrMsg;
                }
            },
        });
        field.setValidator(validatorList);
    }, []);
    var result = {
        onProgress: function (file) {
            var _a;
            var fileIndex = fileListRef.current.findIndex(function (item) {
                if (item.key)
                    return item.key === file.key;
                return item.originFile === file.originFile;
            });
            if (fileIndex >= 0) {
                fileListRef.current[fileIndex] = file;
            }
            else {
                fileListRef.current.push(file);
            }
            field.onInput(field.value);
            (_a = props.onProgress) === null || _a === void 0 ? void 0 : _a.call(props, file);
        },
        onError: function (err, file) {
            var _a;
            fileListRef.current = fileListRef.current.filter(function (item) {
                return item.originFile !== file.originFile;
            });
            field.onInput(field.value);
            (_a = props.onError) === null || _a === void 0 ? void 0 : _a.call(props, err, file);
        },
        onRemove: function (file) {
            var _a;
            fileListRef.current = fileListRef.current.filter(function (item) {
                return item.originFile !== file.originFile;
            });
            field.onInput(field.value);
            (_a = props.onRemove) === null || _a === void 0 ? void 0 : _a.call(props, file);
        },
    };
    return result;
}
