import { __assign } from "tslib";
import * as React from 'react';
import NextBadge from '@fusion/lib/badge';
export var CnBadge = function (props) {
    return React.createElement(NextBadge, __assign({ "data-name": "CnBadge" }, props));
};
CnBadge.displayName = 'CnBadge';
/**
 * @deprecated  请使用 CnBadge 替换
 */
export var Badge = CnBadge;
