import { __spreadArray } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { CnTagSelectable } from '@/components/cn-tag';
import { useDerivedValue } from '@/components/cn-utils/hooks';
import { CnBox } from '@/components/cn-box';
// 检查值是否能够完全等同
var checkSelected = function (val, values) {
    if (Array.isArray(values)) {
        return values.includes(val);
    }
    else {
        return val === values;
    }
};
export var CnSelector = function (props) {
    var className = props.className, _a = props.dataSource, dataSource = _a === void 0 ? [] : _a, _b = props.mode, mode = _b === void 0 ? 'single' : _b, onChange = props.onChange, disabled = props.disabled;
    var _c = useDerivedValue(props, []), value = _c[0], setValue = _c[1], isControlled = _c[2];
    var handleClick = function (v) {
        if (mode === 'single') {
            var hasChecked = checkSelected(v, value);
            if (!isControlled) {
                setValue(hasChecked ? [] : [v]);
            }
            onChange && onChange(hasChecked ? undefined : v);
        }
        else {
            var newValue = __spreadArray([], value, true);
            if (newValue.includes(v)) {
                newValue.splice(newValue.indexOf(v), 1);
            }
            else {
                newValue.push(v);
            }
            if (!isControlled) {
                setValue(newValue);
            }
            onChange && onChange(newValue);
        }
    };
    var clsPrefix = 'cn-ui-selector';
    var items = dataSource.map(function (it) {
        var v = typeof it === 'object' ? it.value : it;
        var label = typeof it === 'object' ? it.label : it;
        var itemDisabled = disabled || (typeof it === 'object' ? it.disabled : false);
        return (React.createElement(CnTagSelectable, { key: v, disabled: itemDisabled, onChange: function () {
                if (itemDisabled)
                    return;
                handleClick(v);
            }, checked: checkSelected(v, value), type: "primary" }, label));
    });
    return (React.createElement("div", { className: classNames(clsPrefix, "".concat(clsPrefix, "--medium"), className) },
        React.createElement(CnBox, { wrap: true, direction: "row", spacing: 8 }, items)));
};
