import { pluginManager } from '@/components/cn-utils/plugin';
// CnFormat.registerFormat('CnArrayView', (props: CnFormatProps) => React.ReactNode)
export var registerFormat = function (name, render) {
    pluginManager.registerPlugin([
        {
            componentName: 'CnFormat',
            plugins: [
                {
                    name: "CnFormatPlugin-".concat(name),
                    sourceCode: function (props, context) {
                        var format = props.format;
                        if ((format === null || format === void 0 ? void 0 : format.toLowerCase()) === name.toLowerCase() && !(props === null || props === void 0 ? void 0 : props.cell)) {
                            props.cell = render;
                        }
                        return { props: props, context: context };
                    },
                    lowCode: {
                        getPrototypeList: function () { },
                    },
                },
            ],
        },
    ]);
};
export var getAllSupportFormat = function () {
    var allGlobalPlugins = pluginManager.getPluginsByComponentName('CnFormat');
    var allSupportFormat = [];
    allGlobalPlugins.forEach(function (plugin) {
        var name = plugin.name;
        allSupportFormat.push(name.replace('CnFormatPlugin-', '').toLowerCase());
    });
    return allSupportFormat;
};
