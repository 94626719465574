import { __assign } from "tslib";
import * as React from 'react';
// import pick from 'lodash/pick';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';
var Plugin = /** @class */ (function () {
    function Plugin(componentName, pluginManager) {
        var _this = this;
        this.componentName = '';
        this.plugin = {};
        this.basicsPlugin = [];
        this.localPlugin = [];
        this.globalPlugin = [];
        this.setGlobalPlugin = function (globalPlugin) {
            if (globalPlugin === void 0) { globalPlugin = []; }
            if (Array.isArray(globalPlugin)) {
                _this.globalPlugin = globalPlugin;
            }
            else if (isPlainObject(globalPlugin)) {
                _this.globalPlugin = Object.values(globalPlugin);
            }
        };
        this.pluginManager = pluginManager;
        this.componentName = componentName;
        pluginManager.getGlobalPlugin();
    }
    // 设置内置插件
    Plugin.prototype.setBasicsPlugin = function (basicsPlugin) {
        if (Array.isArray(basicsPlugin)) {
            this.basicsPlugin = this.basicsPlugin.concat(basicsPlugin);
        }
        else if (isPlainObject(basicsPlugin)) {
            this.basicsPlugin = this.basicsPlugin.concat(Object.values(basicsPlugin));
        }
    };
    Plugin.prototype.setLocalPlugin = function (localPlugin) {
        var _this = this;
        if (localPlugin === void 0) { localPlugin = []; }
        if (typeof localPlugin === 'string') {
            var plugin = this.pluginManager.getPluginByPluginName(localPlugin, this.componentName);
            if (isFunction(plugin === null || plugin === void 0 ? void 0 : plugin.method)) {
                this.localPlugin = [plugin.method];
            }
        }
        if (Array.isArray(localPlugin)) {
            this.localPlugin = localPlugin.reduce(function (pre, item) {
                if (isFunction(item)) {
                    pre.push(item);
                }
                else if (isPlainObject(localPlugin)) {
                    pre.push.apply(pre, Object.values(localPlugin));
                }
                else if (typeof item === 'string') {
                    var plugin = _this.pluginManager.getPluginByPluginName(item, _this.componentName);
                    if (isFunction(plugin === null || plugin === void 0 ? void 0 : plugin.method)) {
                        pre.push(plugin.method);
                    }
                }
                return pre;
            }, []);
        }
        else if (isPlainObject(localPlugin)) {
            this.localPlugin = Object.values(localPlugin);
        }
    };
    Plugin.prototype.getPlugin = function () {
        return this.basicsPlugin.concat(this.localPlugin).concat(this.globalPlugin);
    };
    return Plugin;
}());
export { Plugin };
// 自动注册到全局的插件
//
var PluginManager = /** @class */ (function () {
    function PluginManager() {
        this.plugin = {};
        this.registeredPlugin = {};
        this.getGlobalPlugin();
    }
    PluginManager.prototype.getGlobalPlugin = function () {
        var plugin = window.CN_UI_PLUGIN || [];
        for (var _i = 0, plugin_1 = plugin; _i < plugin_1.length; _i++) {
            var _a = plugin_1[_i], name = _a.name, pluginFun = _a.plugin, pluginFuns = _a.plugins;
            if (Array.isArray(this.plugin[name])) {
                this.plugin[name] = this.plugin[name].concat(pluginFuns !== null && pluginFuns !== void 0 ? pluginFuns : pluginFun);
            }
            else {
                this.plugin[name] = pluginFuns !== null && pluginFuns !== void 0 ? pluginFuns : pluginFun;
            }
        }
    };
    PluginManager.prototype.registerPlugin = function (pluginList) {
        var _a;
        var _b;
        var registerPlugin = (_b = pluginList === null || pluginList === void 0 ? void 0 : pluginList.map) === null || _b === void 0 ? void 0 : _b.call(pluginList, function (plugin) {
            return {
                name: plugin.componentName,
                plugins: plugin.plugins.map(function (item) { return ({
                    name: item === null || item === void 0 ? void 0 : item.name,
                    method: item.sourceCode,
                    lowCodeConfig: item.lowCode,
                    oneCode: item.oneCode,
                }); }),
            };
        });
        window.CN_UI_PLUGIN = Array.isArray(window.CN_UI_PLUGIN)
            ? (_a = window.CN_UI_PLUGIN).concat.apply(_a, registerPlugin) : registerPlugin;
        // 注册即生效，后续需要删除 loadPlugins 和 loadFullPlugins 能力
        this.getGlobalPlugin();
        this.loadFullPlugins();
    };
    PluginManager.prototype.loadPlugins = function (componentName) {
        var _this = this;
        if (Array.isArray(componentName)) {
            componentName.forEach(function (name) {
                _this.registeredPlugin[name] = _this.plugin[name] || [];
            });
        }
        else {
            this.registeredPlugin[componentName] = this.plugin[componentName] || [];
        }
    };
    PluginManager.prototype.loadFullPlugins = function () {
        this.registeredPlugin = __assign({}, this.plugin);
    };
    PluginManager.prototype.fullRegisterPlugins = function () {
        this.loadFullPlugins();
    };
    // getPluginsByComponentName(componentName) {
    PluginManager.prototype.getPluginsByComponentName = function (componentName) {
        var _this = this;
        var _a;
        if (Array.isArray(componentName)) {
            return componentName.reduce(function (plugin, name) {
                return plugin.concat(_this.registeredPlugin[name] || []);
            }, []);
            // .map((item) => item.method);
        }
        return (_a = this.registeredPlugin[componentName]) !== null && _a !== void 0 ? _a : [];
    };
    // getPluginsByPluginName(pluginName) {
    PluginManager.prototype.getPluginByPluginName = function (pluginName, componentName) {
        var _a, _b;
        var componentPlugin = (_a = this.plugin[componentName]) !== null && _a !== void 0 ? _a : [];
        var fullPluginList = Object.values(this.plugin).flat();
        var pluginList = componentName ? componentPlugin : fullPluginList;
        return (_b = pluginList === null || pluginList === void 0 ? void 0 : pluginList.find) === null || _b === void 0 ? void 0 : _b.call(pluginList, function (item) { return item.name === pluginName; });
    };
    return PluginManager;
}());
export var pluginManager = new PluginManager();
export function useCreatePluginInstance(pluginName, localPlugin) {
    var plugin = React.useMemo(function () {
        var plugin = new Plugin(pluginName, pluginManager);
        localPlugin && plugin.setLocalPlugin(localPlugin);
        plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName(pluginName)
            .map(function (item) { return item.method; }));
        return plugin;
    }, []);
    return plugin;
}
export function takeExpandPropsFormPlugins(plguins, props) {
    return plguins.reduce(function (expandProps, handleFun) {
        return Object.assign(expandProps, handleFun(expandProps));
    }, __assign({}, props));
}
export function HocBaseComponents(BaseComponent, options) {
    var _a;
    var props = options.props, _b = options.plugins, plugins = _b === void 0 ? [] : _b, ref = options.ref, dataName = options.dataName;
    var baseComponentRef = React.useRef();
    // console.log(reffield.current, 'reffield.current1');
    // function HocComponet({
    //   BaseComponent,
    //   plguins = [],
    //   ref,
    //   dataName,
    //   ...prpos
    // }) {
    // 初始化 expandProps 对象，包含 props、context 和 BaseComponent
    var expandProps = {
        props: __assign({}, props),
        context: {},
        BaseComponent: BaseComponent,
    };
    var _loop_1 = function (handleFun) {
        // 执行插件处理函数，获取返回的 props、context 和 render
        var _c = handleFun(expandProps.props, expandProps.context), props_1 = _c.props, context = _c.context, render = _c.render;
        // 如果 render 是一个函数且 baseComponentRef.current 不存在
        if (isFunction(render) && !baseComponentRef.current) {
            var BaseComponent_1 = expandProps.BaseComponent;
            // 将 expandProps.BaseComponent 设置为一个新的函数组件
            expandProps.BaseComponent = function (props) {
                return render(props, BaseComponent_1);
            };
        }
        // 更新 expandProps 对象，合并新的 props 和 context
        expandProps = __assign(__assign({}, expandProps), { props: props_1, context: context });
    };
    // 遍历插件数组，对每个插件执行处理函数
    for (var _i = 0, plugins_1 = plugins; _i < plugins_1.length; _i++) {
        var handleFun = plugins_1[_i];
        _loop_1(handleFun);
    }
    baseComponentRef.current =
        (_a = baseComponentRef.current) !== null && _a !== void 0 ? _a : expandProps.BaseComponent;
    var ExpandBaseComponent = baseComponentRef.current;
    if (dataName) {
        expandProps.props['data-name'] = dataName;
    }
    var eProps = __assign({}, expandProps.props);
    if (ref) {
        eProps.ref = ref;
    }
    if (baseComponentRef.current) {
        return React.createElement(ExpandBaseComponent, __assign({}, eProps));
    }
    return React.createElement(BaseComponent, __assign({}, eProps));
    // }
    // return (
    //   <HocComponet
    //     BaseComponent={BaseComponent}
    //     plguins={plguins}
    //     ref={ref}
    //     dataName={dataName}
    //     {...props}
    //   />
    // );
}
