import { __assign } from "tslib";
import React from 'react';
import NextSearch from '@fusion/lib/search';
export var CnSearch = React.forwardRef(function (props, ref) {
    return React.createElement(NextSearch, __assign({ "data-name": "CnSearch", ref: ref }, props));
});
CnSearch.displayName = 'CnSearch';
/**
 * @deprecated  请使用 CnSearch 替换
 */
export var Search = NextSearch;
