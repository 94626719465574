import { __assign } from "tslib";
import * as React from 'react';
import { Balloon } from '@/components/fusion';
import classnames from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import './index.scss';
export function CnBalloon(props) {
    var cls = classnames('cn-balloon-style', props.className);
    return (React.createElement(Balloon, __assign({}, props, { className: cls, "data-name": "CnBalloon" }), props.children));
}
CnBalloon.displayName = 'CnBalloon';
/**
 * @deprecated 请直接使用 CnTooltip
 */
CnBalloon.Tooltip = CnTooltip;
/**
 * @deprecated 请直接使用 CnBalloon
 */
export { Balloon };
