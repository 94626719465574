import { __assign, __rest } from "tslib";
import React from 'react';
import { CnBreadcrumb, CnBreadcrumbItem } from '@/components/cn-breadcrumb';
/**
 * page header 区域
 * 可用于渲染标题 or 面包屑 or 自定义元素
 */
export function PageHeader(props) {
    var title = props.title, action = props.action, cnBreadcrumbProps = props.cnBreadcrumbProps;
    var dataSource = cnBreadcrumbProps.dataSource, restProps = __rest(cnBreadcrumbProps, ["dataSource"]);
    if (dataSource.length) {
        return (React.createElement("div", { className: "cn-ui-page-title" },
            React.createElement(CnBreadcrumb, __assign({}, restProps), dataSource.map(function (_a) {
                var children = _a.children, item = __rest(_a, ["children"]);
                return (React.createElement(CnBreadcrumbItem, __assign({}, item, { onClick: function () {
                        var _a;
                        // @ts-ignore jumpTo 由标准底座实现
                        (_a = window === null || window === void 0 ? void 0 : window.jumpTo) === null || _a === void 0 ? void 0 : _a.call(window, item.link);
                    } }), children));
            })),
            action ? React.createElement("div", null, action) : null));
    }
    if (title || action) {
        return (React.createElement("div", { className: "cn-ui-page-title" },
            title ? React.createElement("div", null, title) : null,
            action ? React.createElement("div", null, action) : null));
    }
    return null;
}
