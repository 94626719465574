import { __rest } from "tslib";
import React from 'react';
import locale from '@/locales';
import { withI18n } from 'panda-i18n';
import { ShellContext } from './context';
import { CnShellContainer } from './shell';
import './shell.scss';
export * from './utils/registerSlot';
export var CnShell = withI18n(function (props) {
    var children = props.children, className = props.className, restProps = __rest(props, ["children", "className"]);
    return (React.createElement(ShellContext, { shellProps: restProps },
        React.createElement(CnShellContainer, { className: className }, props.children)));
}, {
    locale: locale,
    componentName: 'CnShell',
});
CnShell.defaultProps = {
    sideBarVisible: true,
    headerVisible: true,
    theme: 'normal',
};
