import isPlainObject from 'lodash/isPlainObject';
export default (function (value, dataSource) {
    var getSelected = function () {
        if (isPlainObject(value)) {
            return {
                label: value.label || value.value,
                value: value.value,
            };
        }
        return {
            label: value,
            value: value,
        };
    };
    var getLabel = function (target) {
        var _a;
        return (((_a = dataSource === null || dataSource === void 0 ? void 0 : dataSource.find(function (item) { return item.value === (target === null || target === void 0 ? void 0 : target.value); })) === null || _a === void 0 ? void 0 : _a.label) ||
            target.label);
    };
    return getLabel(getSelected());
});
