import { useState } from 'react';
/**
 * 受控逻辑一般存在以下 3 种逻辑
 * 1. 只有 props.value 存在，props.hasOwnProperty("value")
 * 2. 只有 props.onChange 存在
 * 3. props.value 和 props.onChange 同时存在
 * CNUI 采用的是第一种，同时内部将兼容处理 params.onChange 存在和不存在两种场景
 *
 * 注意：需要透传组件接受到的 props 给第一个参数，避免受控逻辑判断错误
 */
export var useControlled = function (props, options) {
    var _a = options || {}, _b = _a.defaultValuePropName, defaultValuePropName = _b === void 0 ? 'defaultValue' : _b, _c = _a.valuePropName, valuePropName = _c === void 0 ? 'value' : _c, _d = _a.onChangePropName, onChangePropName = _d === void 0 ? 'onChange' : _d;
    var defaultValue = props[defaultValuePropName];
    var propsValue = props[valuePropName];
    var propsOnChange = props[onChangePropName];
    var isControlled = props.hasOwnProperty(valuePropName);
    /**
     * 当非受控的时候使用内部的 innerValue 兜底，当受控的时候，innerValue 自动失去作用
     * 这里的默认值之所有没有使用 value || defaultValue，是因为如果用户传了 params.value，受控的时候，最终不会消费 innerValue
     */
    var _e = useState(defaultValue), innerValue = _e[0], setInnerValue = _e[1];
    // 对外暴露的 value，用于替换 props.value
    var value = isControlled ? propsValue : innerValue;
    var propsHasOnChange = propsOnChange && typeof propsOnChange === 'function';
    /**
     * 对外暴露的 onChange，用于替换 props.onChange
     * 当用户未传 onChange 的时候，使用空函数兜底，避免外部调用报错
     */
    var onChange = (function () { });
    if (isControlled) {
        // 1. 处理受控场景
        if (propsHasOnChange) {
            onChange = propsOnChange;
        }
    }
    else {
        // 2. 处理非受控场景
        if (propsHasOnChange) {
            // 由于外部没有传递 value 状态，所以内部包裹一个函数，通过 innerValue 暂存状态，同时 onChange 暴露给外部
            onChange = (function () {
                var paramList = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    paramList[_i] = arguments[_i];
                }
                // @ts-ignore
                propsOnChange.apply(void 0, paramList);
                setInnerValue(paramList[0]);
            });
        }
        else {
            onChange = setInnerValue;
        }
    }
    return [value, onChange];
};
