import { __assign, __rest } from "tslib";
import * as React from 'react';
import $i18n, { withI18n } from 'panda-i18n';
import cx from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CnIcon } from '@/components/cn-icon';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import omit from 'lodash/omit';
import { CnMessage } from '@/components/cn-message';
import locale from '@/locales';
import { CnReadOnlyContainer } from './cn-read-only-container';
import stringRender from './render/string-render';
import numberRender from './render/number-render';
import booleanRender from './render/boolean-render';
import enumRender from './render/enum-render';
import tableCellRender from './render/table-cell-render';
import './index.scss';
var isEmpty = function (value) {
    if (isNil(value)) {
        // null or undefined
        return true;
    }
    if (value === '') {
        return true;
    }
    if (isArray(value) && !value.length) {
        return true;
    }
    return false;
};
// 单值渲染
var readOnlyItemRender = function (type, value, valueSeparator, emptyRender, dataSource) {
    if (isEmpty(value)) {
        return emptyRender();
    }
    if (type === 'string' || (!type && isString(value))) {
        return stringRender(value);
    }
    if (type === 'number' || (!type && isNumber(value))) {
        return numberRender(value);
    }
    if (type === 'boolean' || (!type && isBoolean(value))) {
        return booleanRender(value);
    }
    if (type === 'enum') {
        return enumRender(value, dataSource);
    }
    return $i18n.get({
        id: 'UnsupportedTypes',
        dm: '不支持的类型',
        ns: 'CnReadOnly',
    });
};
export var CnReadOnly = withI18n(function (props) {
    var _a;
    var emptyRender = props.emptyRender, type = props.type, value = props.value, valueSeparator = props.valueSeparator, dataSource = props.dataSource, allowCopy = props.allowCopy, tableCellProps = props.tableCellProps, otherProps = __rest(props, ["emptyRender", "type", "value", "valueSeparator", "dataSource", "allowCopy", "tableCellProps"]);
    if (Array.isArray(value)) {
        var notEmptyValueArr = value.filter(function (valueItem) { return !isEmpty(valueItem); });
        var renderValueArr = notEmptyValueArr.map(function (valueItem) {
            return readOnlyItemRender(type, valueItem, valueSeparator, emptyRender, dataSource);
        });
        return (React.createElement(CnReadOnlyContainer, __assign({ value: value }, otherProps), renderValueArr.join(valueSeparator)));
    }
    if (type === 'tableCell') {
        return (
        // @ts-ignore
        React.createElement("div", __assign({ className: cx((_a = {
                    'cn-ui-read-only': true,
                    'table-cell': true
                },
                _a[props.className] = !!props.className,
                _a)) }, omit(otherProps, ['showFolder'])), tableCellRender(tableCellProps)));
    }
    var content = readOnlyItemRender(type, value, valueSeparator, emptyRender, dataSource);
    return (React.createElement(CnReadOnlyContainer, __assign({ "data-name": "CnReadOnly", value: value }, otherProps),
        content,
        allowCopy && (React.createElement(CopyToClipboard, { className: "cn-ui-read-only-copy", text: content, onCopy: function () {
                return CnMessage.success($i18n.get({
                    id: 'ReplicationSuccessful',
                    dm: '复制成功',
                    ns: 'CnReadOnly',
                }));
            } },
            React.createElement(CnIcon, { type: "copy", size: "medium" })))));
}, {
    componentName: 'CnReadOnly',
    locale: locale,
});
CnReadOnly.defaultProps = {
    size: 'medium',
    emptyRender: function () { return '- -'; },
    valueSeparator: ',',
    line: 3,
    showFolder: false,
    allowCopy: false,
};
