import { __awaiter, __generator } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { createForm, isForm } from '@formily/core';
import { isFn, applyMiddleware } from '@formily/shared';
import { Observer } from '@formily/react';
import { loading, createPortalRoot } from '@/form/__builtins__';
import getDialogProps from './utils/get-dialog-props';
import { RenderQuickDialogForm } from './render-quick-dialog-form';
export function cnQuickDialogForm(originDialogProps, schema, 
/**
 * 点击提交后的请求配置
 */
requestConfig) {
    var _this = this;
    // 获取对话框属性
    var dialogProps = getDialogProps(originDialogProps);
    // 创建渲染DOM
    var renderDom = document.createElement('div');
    // 创建Portal根节点
    var portalRoot = createPortalRoot(renderDom, 'form-dialog');
    // 初始化缓存Promise
    var cachePromise;
    // 初始化组件
    var components = {};
    // 初始化表单
    var form;
    // 初始化打开中间件
    var openMiddlewares = [];
    // 初始化确认中间件
    var confirmMiddlewares = [];
    // 初始化取消中间件
    var cancelMiddlewares = [];
    // 下面有重新赋值，不要删除，执行该函数，其实背后执行的是 formDialog.close 方法
    var closeDialog = function () { };
    // 加载文本
    var loadingText = $i18n.get({ id: 'Loading', dm: '加载中', ns: 'CnForm' });
    // 打开对话框
    var openDialog = function (_form, resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
        var __form, e_1;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loading(loadingText, function () {
                            return applyMiddleware(_form, openMiddlewares);
                        })];
                case 1:
                    __form = _a.sent();
                    // 创建表单
                    if (!form) {
                        form = isForm(__form) ? __form : createForm(__form);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    reject(e_1);
                    return [3 /*break*/, 3];
                case 3:
                    // 渲染Portal
                    portalRoot.render(function () {
                        return (React.createElement(Observer, null, function () { return (React.createElement(RenderQuickDialogForm, { props: dialogProps, form: form, components: components, confirmMiddlewares: confirmMiddlewares, schema: schema, requestConfig: requestConfig, onOk: function (values) {
                                resolve(values);
                                closeDialog();
                            }, onClose: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!cancelMiddlewares.length) return [3 /*break*/, 2];
                                            return [4 /*yield*/, loading(loadingText, function () {
                                                    return applyMiddleware(form, cancelMiddlewares);
                                                })];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2:
                                            closeDialog();
                                            return [2 /*return*/];
                                    }
                                });
                            }); } })); }));
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    /** 对外暴露的对象方法 */
    var formDialog = {
        // 注册组件
        registerComponents: function (_components) {
            components = _components;
            return formDialog;
        },
        // 打开中间件
        forOpen: function (middleware) {
            if (isFn(middleware))
                openMiddlewares.push(middleware);
            return formDialog;
        },
        // 确认中间件
        forConfirm: function (middleware) {
            if (isFn(middleware))
                confirmMiddlewares.push(middleware);
            return formDialog;
        },
        // 取消中间件
        forCancel: function (middleware) {
            if (isFn(middleware))
                cancelMiddlewares.push(middleware);
            return formDialog;
        },
        // 打开对话框
        open: function (_form) {
            if (cachePromise)
                return cachePromise;
            // 生成并缓存 Promise
            cachePromise = new Promise(function (resolve, reject) {
                openDialog(_form, resolve, reject);
            });
            return cachePromise;
        },
        // 关闭对话框
        close: function () {
            var _a;
            (_a = dialogProps.afterClose) === null || _a === void 0 ? void 0 : _a.call(dialogProps);
            portalRoot.unmount();
            cachePromise = undefined;
        },
    };
    // 由于 formDialog 在 closeDialog 申明之后再声明的，而 formDialog 和 closeDialog 存在互相调用的逻辑，所以此处重新赋值，不要删除该逻辑
    closeDialog = function () { return formDialog.close(); };
    document.body.appendChild(renderDom);
    return formDialog;
}
