import React, { useContext } from 'react';
import $i18n from 'panda-i18n';
import { DropDown2 as DropDown } from '@/components/cn-base-table/view/dep';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { CnButton } from '@/components/cn-button';
import { ColumnsStateContext } from './context';
import ColumnsFixLeft from './columns-fix-left';
import ColumnsFixRight from './columns-fix-right';
import { ColumnItem } from './column-item';
export function ColumnsSettingPopup() {
    var _a = useContext(ColumnsStateContext), fixLeftColumns = _a.fixLeftColumns, fixRightColumns = _a.fixRightColumns, noFixColumns = _a.noFixColumns, updateColumns = _a.updateColumns;
    return (React.createElement(React.Fragment, null,
        React.createElement(CnScrollBar, { className: "cn-table-column-setting-wrapper cn-table-column-setting-popup-wrapper" },
            React.createElement(ColumnsFixLeft, null),
            fixLeftColumns.length || fixRightColumns.length ? (React.createElement("div", { className: "cn-table-column-setting-wrapper-title" }, $i18n.get({ id: 'NotFixed', dm: '不固定', ns: 'CnTable' }))) : null,
            noFixColumns.length ? (React.createElement("div", { className: "cn-table-column-setting" }, noFixColumns.map(function (dataItem, index) { return (React.createElement(ColumnItem, { position: "center", dataItem: dataItem, key: dataItem.dataIndex, index: index })); }))) : null,
            React.createElement(ColumnsFixRight, null)),
        React.createElement("div", { className: "cn-table-column-set__footer" },
            React.createElement(CnButton, { onClick: function () { return DropDown.hide(); } }, $i18n.get({ id: 'Cancel', dm: '取消', ns: 'CnTable' })),
            React.createElement(CnButton, { onClick: function () {
                    DropDown.hide();
                    updateColumns();
                }, type: "primary" }, $i18n.get({ id: 'Confirmation', dm: '确认', ns: 'CnTable' })))));
}
