import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import ResizeObserver from 'resize-observer-polyfill';
var CnEllipsis = /** @class */ (function (_super) {
    __extends(CnEllipsis, _super);
    function CnEllipsis(props) {
        var _this = _super.call(this, props) || this;
        _this.saveTextRef = function (ref) {
            _this.textRef = ref;
        };
        _this.saveEllipseRef = function (ref) {
            _this.ellipseRef = ref;
        };
        _this.state = {
            isOverflow: false,
        };
        return _this;
    }
    CnEllipsis.prototype.componentDidMount = function () {
        var _this = this;
        this.resizeObserver = new ResizeObserver(function () {
            _this.updateIsOverflow();
        });
        this.resizeObserver.observe(this.ellipseRef);
    };
    CnEllipsis.prototype.componentDidUpdate = function () {
        this.updateIsOverflow();
    };
    CnEllipsis.prototype.componentWillUnmount = function () {
        this.resizeObserver.disconnect();
    };
    CnEllipsis.prototype.updateIsOverflow = function () {
        if (this.textRef && this.textRef.scrollWidth > this.textRef.offsetWidth) {
            !this.state.isOverflow &&
                this.setState({
                    isOverflow: true,
                });
        }
        else {
            this.state.isOverflow &&
                this.setState({
                    isOverflow: false,
                });
        }
    };
    CnEllipsis.prototype.render = function () {
        var _a;
        var isOverflow = this.state.isOverflow;
        var _b = this.props, children = _b.children, className = _b.className, ellipsisPosition = _b.ellipsisPosition, endCharCount = _b.endCharCount, hasTooltip = _b.hasTooltip, cnTooltipProps = _b.cnTooltipProps, otherProps = __rest(_b, ["children", "className", "ellipsisPosition", "endCharCount", "hasTooltip", "cnTooltipProps"]);
        var classes = cx((_a = {
                'cn-ui-ellipsis': true
            },
            _a[className] = !!className,
            _a));
        // 默认
        var content = (React.createElement("div", { ref: this.saveTextRef, className: "cn-ui-ellipsis-header" }, children));
        // 省略号出现在中间
        if (isOverflow && ellipsisPosition === 'middle') {
            var childrenStr = "".concat(children);
            var startChildren = childrenStr.substr(0, childrenStr.length - endCharCount);
            var endChildren = childrenStr.substr(-endCharCount);
            content = (React.createElement(React.Fragment, null,
                React.createElement("div", { ref: this.saveTextRef, className: "cn-ui-ellipsis-header" }, startChildren),
                React.createElement("div", { className: "cn-ui-ellipsis-footer" }, endChildren)));
        }
        var trigger = (React.createElement("div", __assign({ ref: this.saveEllipseRef, className: classes }, otherProps), content));
        // 出现 Balloon
        if (isOverflow && hasTooltip) {
            return (React.createElement("div", { ref: this.saveEllipseRef },
                React.createElement(CnTooltip, __assign({ v2: true, trigger: trigger }, cnTooltipProps), children)));
        }
        return trigger;
    };
    CnEllipsis.displayName = 'CnEllipsis';
    CnEllipsis.defaultProps = {
        ellipsisPosition: 'end',
        endCharCount: 8,
        hasTooltip: true,
    };
    return CnEllipsis;
}(React.Component));
export { CnEllipsis };
