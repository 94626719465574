import { __assign } from "tslib";
import * as React from 'react';
import { Pagination as NextPagination } from '@fusion/lib';
export var CnPagination = function (props) {
    return React.createElement(NextPagination, __assign({ "data-name": "CnPagination" }, props));
};
CnPagination.displayName = 'CnPagination';
/**
 * @deprecated  请使用 CnPagination 替换
 */
export var Pagination = NextPagination;
