import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { PandaConfigProvider } from 'panda-i18n';
import { useSizeChange } from '@/components/cn-utils';
import { CnPageContent } from './page-content';
export function CnPageView(props) {
    var className = props.className, restProps = __rest(props, ["className"]);
    var size = useSizeChange(props).size;
    return (React.createElement(PandaConfigProvider, null,
        React.createElement("div", { className: classNames('cn-ui-page', "cn-ui-page-".concat(size), className), "data-name": "CnPage" },
            React.createElement(CnPageContent, __assign({}, restProps)),
            React.createElement("div", { className: "cn-ui-page-footer-placeholder" }))));
}
